import { React, useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

// NOTE: How to change pin styles: https://developers.google.com/maps/documentation/javascript/advanced-markers/basic-customization
// NOTE: It is possible to add any type of content in AdvancedMarkerElement.content: https://developers.google.com/maps/documentation/javascript/advanced-markers/graphic-markers

const Marker = ({ map, position, children }) => {
  const markerRef = useRef();
  const rootRef = useRef();

  const pinStyle = {
    scale: 1
    // background: '#2F64F5',
    // borderColor: '#2651C7',
    // glyphColor: '#EBF1FF'
    // glyph: 'Text Here'
  };

  const pin = new window.google.maps.marker.PinElement(pinStyle);
  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement('div');
      rootRef.current = createRoot(container);
      markerRef.current = new window.google.maps.marker.AdvancedMarkerElement({
        position,
        content: pin.element
      });
    }
  }, []);

  useEffect(() => {
    if (!markerRef.current || !rootRef.current) return;
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = map;
  }, [map, position, children]);

  return <></>;
};

export default Marker;
