import utils from '..';
import colors from '../../assets/colors';
import config from '../../config';
import calendarUtils from '../calendar.js';

import API from '../../services/api';

/**
 * Return a list of Device IDs to filter on the queries
 * @param {*} devices Array of device objects
 */
const getDeviceQuery = devices => {
  let deviceList = '';

  devices.forEach(device => {
    deviceList += `"${device.id}", `;
  });

  // Remove final comma
  deviceList = deviceList.substring(0, deviceList.length - 2);

  return deviceList ? `deviceId in (${deviceList})` : '';
};

/**
 * Move tables to the end of the array so they can be expanded during PDF printing
 * @param {*} widgetList Array of array with all the widgets groupped by row
 */
const moveTablesToEnd = widgetList => {
  let widgetListPDF = widgetList;

  // Remove all table widgets
  widgetListPDF = filterWidgets(widgetList, widgetListPDF, 'display', 'table', false, true);

  // Add table widgets at the end
  widgetListPDF = [...widgetListPDF, ...filterWidgets(widgetList, widgetListPDF, 'display', 'table', true, true)];

  return widgetListPDF;
};

/**
 * Filter the array of widgets based on a given pair of field and value
 * @param {*} source Array with original widgets, to be read from
 * @param {*} filterList Array with values to filter
 * @param {*} field Name of the field to be used on the filter
 * @param {*} value Constant value to be compared on the filter
 * @param {*} equals Flag to determine if widget.field should be equal or different from value
 * @param {*} compareConstant Flag to determine if comparison should be made against constant value
 */
const filterWidgets = (source, filterList, field, value, equals, compareConstant) => {
  const filteredWidgets = source
    // Remove widgets that are not selected from each row
    .map(widgetRow => {
      return widgetRow.filter(widget =>
        filterList.some(enabledWidget => {
          if (compareConstant) {
            return equals ? widget[field] === value : widget[field] !== value;
          }

          return enabledWidget === widget[field];
        })
      );
    })
    // Clean up array, removing empty rows
    .filter(widgetRow => widgetRow.length > 0);

  return filteredWidgets;
};

const getFilteredWidgets = (defaultWidgets, customWidgets) => {
  if (customWidgets && customWidgets.length > 0) {
    const filterResult = filterWidgets(defaultWidgets, customWidgets, 'id');
    // Keep all widgets if current selection is empty
    return filterResult.length > 0 ? filterResult : defaultWidgets;
  }

  return defaultWidgets;
};

const getWidgetConfig = (widgetConfig, companyId) => {
  if (!widgetConfig || !companyId) {
    return null;
  }

  const { apiName, urlPath, requestUrl } = widgetConfig;
  let newPath = urlPath;

  const baseURL = `${config[apiName ? apiName : 'queryApi'].baseUrl}/companies/${companyId}${newPath}`;

  const newConfig = {
    ...widgetConfig,
    requestUrl: requestUrl ? requestUrl : baseURL,
    accessToken: API.access_token,
    colors: colors.mainPallet
  };

  return JSON.stringify(newConfig);
};

const replaceCustomVariables = (widget, values) => {
  if (!widget.valuesToReplace) {
    return widget;
  }

  const newWidget = utils.deepClone(widget);

  newWidget.valuesToReplace.forEach(item => {
    const searchValue = `$${item.replaceWith}`;
    const originalValue = utils.get(newWidget, item.path);
    const stringToReplace = values[item.replaceWith];
    let parsedValue = originalValue;

    if (typeof originalValue === 'string') {
      const newValue = originalValue.replaceAll(searchValue, stringToReplace);
      parsedValue = item.type === 'number' ? +newValue : newValue;
    }

    if (typeof originalValue === 'object') {
      const stringObject = JSON.stringify(originalValue);
      const newValue = stringObject.replaceAll(searchValue, stringToReplace);
      parsedValue = item.type === 'number' ? +newValue : newValue;
      parsedValue = JSON.parse(parsedValue);
    }

    utils.set(newWidget, item.path, parsedValue);
  });

  return newWidget;
};

const getDeviceFilterQuery = (devices = []) => {
  const deviceList = Array.from(devices).map(device => `"${device}"`);
  // Create device filter
  const deviceFilter = deviceList.length ? `deviceId in (${deviceList.join(', ')})` : '';

  return [deviceFilter];
};

const addFilterQuery = (currentFilterQuery, newFilter, filterName) => {
  const parsedFilter = newFilter.length
    ? `${filterName} in (${newFilter.map(filter => `"${filter.name}"`).join(', ')})`
    : '';
  return [...currentFilterQuery, parsedFilter];
};

// get widget option (hamburg menu)
const getWidgetOptions = widget => {
  let widgetOptions = widget.options || [];

  const exportOptions = widget.exportOptions
    ? { ...widget.exportOptions, label: 'Export to CSV', function: 'exportToCSV' }
    : { label: 'Export to CSV', function: 'exportToCSV' };

  //Inject toggle options on widget Menu
  if (widget.display === 'bar' || widget.display === 'line' || widget.display === 'donut') {
    const toggleOptions = {
      label: 'Toggle Numbers',
      function: 'toggleChartOptions'
    };

    if (widget.display === 'bar') {
      toggleOptions.customHeader = 'plotOptions.column.dataLabels.enabled';
    } else if (widget.display === 'line') {
      toggleOptions.customHeader = 'plotOptions.series.dataLabels.enabled';
    } else {
      toggleOptions.customHeader = 'plotOptions.pie.dataLabels.enabled';
    }
    widgetOptions = [...widgetOptions, toggleOptions];
  }

  // Inject custom + default options on widget menu
  widgetOptions = [...widgetOptions, exportOptions];
  widgetOptions = JSON.stringify(widgetOptions);
  return widgetOptions;
};

const getStringfiedField = (object, field) => {
  if (typeof object[field] !== 'string') {
    return JSON.stringify(object[field]);
  }

  return object[field];
};

const getMetricsWithFilter = (query, filter) => {
  const firstMetric = query.metrics[0];
  let newMetrics = query.metrics;

  if (query.metrics && Array.isArray(query.metrics) && firstMetric) {
    // Ensure firstMetric.filter is an array or initialize it as an empty array
    // Filter filters array to remove null values
    firstMetric.filter = Array.isArray(firstMetric.filter)
      ? [...firstMetric.filter.filter(Boolean), ...(Array.isArray(filter) ? filter.filter(Boolean) : [])]
      : Array.isArray(filter)
      ? filter.filter(Boolean)
      : [];

    newMetrics = JSON.stringify(query.metrics); // Convert metrics to a JSON string
  }

  return newMetrics;
};

const getWidgetQuery = (newWidget, filters, selectedDashboard, columns, lastUpdated) => {
  const hasCMS = selectedDashboard.isCms;

  if (!filters) return null;

  let newFilters = [];

  if (hasCMS) {
    // Add device filters to query
    newFilters = getDeviceFilterQuery(filters.selectedDevices);
    //TODO: REMOVE THIS newFilters = getDeviceFilterQuery(availableCMS[selectedDashboard.legacyTable].solutionTable, filters.selectedDevices);
    // Add campaign filter
    newFilters = addFilterQuery(newFilters, filters.selectedAdvertisers, columns.advertisers);
    // Add media filter
    newFilters = addFilterQuery(newFilters, filters.selectedMedias, columns.medias);
  } else {
    newFilters = getDeviceFilterQuery(filters.selectedDevices);
    //TODO: REMOVE THIS newFilters = getDeviceFilterQuery(selectedDashboard.legacyTable, filters.selectedDevices);
  }

  let newQuery =
    filters?.interval?.id && newWidget.hasEpoch
      ? { ...newWidget.query, epoch: String(filters.interval.id).toLowerCase() }
      : newWidget.query;

  // Update filters
  const canUpdateFilters = filters.startDate && filters.endDate && filters.timezone;
  newQuery =
    canUpdateFilters && newQuery
      ? {
          ...newQuery,
          startDate: calendarUtils.replaceOffset(filters.startDate, filters.timezone),
          endDate: calendarUtils.replaceOffset(filters.endDate, filters.timezone),
          timezone: filters.timezone,
          lastUpdated, // Sending this timestamp to update widgets on refresh
          // JSON stringify is necessary because those fields are objects/arrays
          // and the API only accepts strings
          filterByTime: getStringfiedField(filters, 'filterByTime'),
          filterByWeekday: getStringfiedField(filters, 'filterByWeekday')
        }
      : null;

  if (newQuery) {
    newQuery = newQuery
      ? JSON.stringify({
          ...newQuery,
          dimensions: getStringfiedField(newQuery, 'dimensions'),
          metrics: getMetricsWithFilter(newQuery, newFilters),
          aggregateResult: getStringfiedField(newQuery, 'aggregateResult'),
          queryFromResult: getStringfiedField(newQuery, 'queryFromResult'),
          encoderParams: getStringfiedField(newQuery, 'encoderParams'),
          classifiers: getStringfiedField(newQuery, 'classifiers'),
          orderBy: getStringfiedField(newQuery, 'orderBy'),
          cmsMetrics: getStringfiedField(newQuery, 'cmsMetrics'),
          cmsDimension: getStringfiedField(newQuery, 'cmsDimension')
        })
      : null;
  }
  return newQuery;
};

export {
  getDeviceQuery,
  getFilteredWidgets,
  getWidgetConfig,
  getWidgetQuery,
  getStringfiedField,
  moveTablesToEnd,
  filterWidgets,
  replaceCustomVariables,
  getWidgetOptions
};
