import { call, put, select } from 'redux-saga/effects';
import types from '../redux/types';
import API from '../services/api';

function loadDefaultTemplates(templates, selectedTemplateId) {
  if (selectedTemplateId) templates[0];
  const selectedTemplate = templates.find(template => template.id === selectedTemplateId);
  return selectedTemplate || templates[0];
}

export function* fetchTemplates() {
  const project = yield select(state => state.projectReducer.selectedProject);
  const company = yield select(state => state.companyReducer.selectedCompany);
  const user = yield select(state => state.userReducer.user);

  // Check if there are any projects in current company
  if (project) {
    const cid = project && project.companyId ? project.companyId : company.id;
    const pid = project && project.id ? project.id : user.preferences.defaultProjectId;

    const datasourcesResponse = yield call(API.getDatasources, cid, pid);
    // Get filter from datasources
    const filterList = datasourcesResponse.datasources.map(datasource => {
      return `'${datasource}' = ANY(compatibleDatasources)`;
    });

    let summaryTemplatesResponse = {};
    summaryTemplatesResponse = yield call(
      API.getTemplates,
      '',
      datasourcesResponse.datasources.length > 0
        ? `(${filterList.join(' OR ')}) AND (collection = 'SUMMARY' AND enabled = true)`
        : ''
    );

    let analyticsTemplatesResponse = {};
    analyticsTemplatesResponse = yield call(
      API.getTemplates,
      '',
      datasourcesResponse.datasources.length > 0
        ? `(${filterList.join(' OR ')}) AND (collection = 'ANALYTICS' AND enabled = true)`
        : ''
    );

    yield put({
      type: types.SELECT_SUMMARY_DASHBOARD,
      payload: loadDefaultTemplates(summaryTemplatesResponse.templates, user.preferences.defaultSummaryDashboard)
    });
    yield put({
      type: types.SELECT_ANALYTICS_DASHBOARD,
      payload: loadDefaultTemplates(analyticsTemplatesResponse.templates, user.preferences.defaultAnalyticsDashboard)
    });
    yield put({ type: types.LOAD_SUMMARY_TEMPLATES, payload: summaryTemplatesResponse.templates });
    yield put({ type: types.LOAD_ANALYTICS_TEMPLATES, payload: analyticsTemplatesResponse.templates });
  }
}
