import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '../../services/api';
import { getUser } from '../../redux/user/actions';
import components from '../../components';
import { AccountsTab, MappingsTab } from './tabs';

import './styles.scss';
import icons from '../../assets/icons';
import ToolBar from '../../components/toolbar';
import { getSelectedCompany } from '../../redux/company/actions';
import { getCmsInfo } from '../../utils/integrations';

const { CustomDropdown, Tabs } = components;

const IntegrationsContainer = ({ lastUpdated }) => {
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [credentials, setCredentials] = useState([]);
  const [sites, setSites] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasPermission, setHasPermission] = useState(true);

  const user = useSelector(getUser);
  const company = useSelector(getSelectedCompany);

  useEffect(() => {
    const fetchSites = async () => {
      const sites = await loadSites();
      setSites(sites);
      setLoading(false);
    };
    setLoading(true);
    fetchSites();
    loadIntegrations();
    loadCredentials();
    loadMappings();
  }, [user, lastUpdated]);

  useEffect(() => {
    loadCredentials();
    loadMappings();
  }, [selectedIntegration]);

  const loadIntegrations = () => {
    if (user) {
      setLoading(true);
      setIntegrations([]);
      setSelectedIntegration({});

      API.getIntegrations('', '', 200)
        .then(res => {
          const { integrations } = res;
          const integrationsList = integrations.map(integration => {
            return {
              ...integration,
              displayName: integration.vendor.charAt(0).toUpperCase() + integration.vendor.slice(1).toLowerCase()
            };
          });
          setIntegrations(integrationsList);
          if (integrations.length > 0) {
            setSelectedIntegration(integrations[0]);
          }
        })
        .catch(error => {
          console.error(error);
          setHasPermission(false);
        })
        .finally(() => setLoading(false));
    }
  };

  const loadCredentials = () => {
    if (user && company && selectedIntegration) {
      setLoading(true);
      setMappings([]);

      const query = encodeURIComponent(`vendor = '${selectedIntegration?.vendor}'`);
      const filter = query !== '' ? query : '';
      API.getCredentials(company.id, '', filter, 200)
        .then(res => {
          setCredentials(res.credentials ? res.credentials : []);
        })
        .catch(error => {
          console.error(error);
          setHasPermission(false);
        })
        .finally(() => setLoading(false));
    }
  };

  const loadMappings = async () => {
    if (selectedIntegration && selectedIntegration.vendor) {
      setLoading(true);
      const query = encodeURIComponent(`vendor = '${selectedIntegration?.vendor}'`);
      const filter = query !== '' ? query : '';
      if (user && company) {
        API.getMappings(company.id, '', filter, 200)
          .then(res => {
            setMappings(res.mappings);
          })
          .catch(error => {
            console.error(error);
            setHasPermission(false);
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const loadSites = async (filter = '', pageToken = '') => {
    try {
      // change enpoint to getSitesByCompany
      const data = await API.getSitesByCompany(company.id, pageToken, filter, 'displayName, id', 200);

      const sites = data.sites;
      const nextPageToken = data.nextPageToken;

      if (nextPageToken !== '') {
        const nextPageSites = await loadSites(filter, nextPageToken);
        return [...sites, ...nextPageSites];
      }

      return sites;
    } catch (error) {
      console.error('Error loding sites:', error);
    }
  };

  const onIntegrationSelected = selection => {
    const integration = getCmsInfo(selection, integrations);
    setSelectedIntegration(integration);
  };

  // Render empty state when User doesn't have any Integration
  if (!selectedIntegration || integrations.length === 0) {
    return (
      <div className="integrations-container">
        <div className="filter-bar">
          <div className="title">
            <img src={icons.integrations} alt="Integrations" />
            <h2>Integrations</h2>
          </div>
        </div>
        <div className="no-data-message flex-column">
          <h1>No Integrations found!</h1>
          <p>If you do have an active CMS Integration, please contact support </p>
        </div>
      </div>
    );
  }

  // Render denied state when User doesn't have permission
  if (!hasPermission) {
    return (
      <div className="integrations-container">
        <div className="filter-bar">
          <div className="title">
            <img src={icons.integrations} alt="Integrations" />
            <h2>Integrations</h2>
          </div>
        </div>
        <div className="no-data-message flex-column">
          <h1>{`You don't have the necessary permission to manage Integrations.`}</h1>
          <p>{`Please contact an administrator or support for further information.`}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="integrations-container">
      <ToolBar
        title={'Integrations'}
        icon={'integrations'}
        renderFilters={
          <CustomDropdown
            items={integrations}
            valueField={'vendor'}
            displayField={'displayName'}
            handleSelection={e => onIntegrationSelected(e.target.value)}
            outline={false}
          />
        }
      />

      <Tabs
        pages={{
          tabs: [
            {
              title: {
                name: 'Accounts',
                icon: 'userDark',
                description: 'Manage your account credentials'
              },
              content: (
                <AccountsTab
                  loading={loading}
                  loadCredentials={loadCredentials}
                  selectedIntegration={selectedIntegration}
                  credentials={credentials}
                />
              )
            },
            {
              title: {
                name: 'Site Mappings',
                icon: 'location',
                description: 'Bind sites to your players'
              },
              content: (
                <MappingsTab
                  credentials={credentials}
                  loading={loading}
                  sites={sites}
                  loadCredentials={loadCredentials}
                  loadMappings={loadMappings}
                  selectedIntegration={selectedIntegration}
                  mappings={mappings}
                />
              )
            }
          ]
        }}
      />
    </div>
  );
};

export default IntegrationsContainer;
